import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import config from "../config";
import { useHistory, useLocation } from "react-router";
import dplogosidebar from "../res/images/dplogosidebar.png";
import cctv from "../res/images/cctv.png";
import ia from "../res/images/ia.svg";
import greendp from "../res/images/greendp.png";
import ml from "../res/images/ml.svg";
import poweroff from "../res/images/poweroff.png";
import robot from "../res/images/robot.svg";
import { Row } from "antd";
import Predictiveanalytics from "./Predictiveanalytics";
import { LogoutOutlined } from "@ant-design/icons";
import axiosObj from "../config/axios";
import logoutblack from "../res/images/logoutblack.png";
import "./caresol.css";

const { div, Content, Sider } = Layout;
const { SubMenu } = Menu;

const Videototext = () => (
  <Row style={{ display: "flex", justifyContent: "center" }}>
    <iframe
      style={{ width: "1000px", height: "650px" }}
      frameBorder="0"
      allowFullScreen="true"
      src="https://datapilot-video-to-text-app-stt-v0nm15.streamlit.app/?embedded=true"
      title="VideoToText"
    />
  </Row>
);

const ChannelAnalytics = () => (
  <Row style={{ display: "flex", justifyContent: "center" }}>
    <iframe
      style={{ width: "1100px", height: "850px" }}
      frameBorder="0"
      allowFullScreen="true"
      src="https://app.powerbi.com/view?r=eyJrIjoiMTFlOTQwNzgtMDEwNy00Yjc2LThhYzgtMzZlMzEyMzExNDI2IiwidCI6IjQxMGYxMDE4LTI1NjUtNDdhZi04YWViLTQ1MzY2OTg0MWZlNyIsImMiOjl9"
      title="VideoToText"
    />
  </Row>
);

const EMS = () => (
  <Row style={{ display: "flex", justifyContent: "center" }}>
    <iframe
      style={{ width: "1100px", height: "900px" }}
      frameBorder="0"
      allowFullScreen="true"
      src="https://app.powerbi.com/view?r=eyJrIjoiNDMyYzYwODAtNjg0Ni00YmQzLTljNDktZDY3ZjFkYzc5MDhjIiwidCI6IjQxMGYxMDE4LTI1NjUtNDdhZi04YWViLTQ1MzY2OTg0MWZlNyIsImMiOjl9"
      title="VideoToText"
    />
  </Row>
);

const ELM = () => (
  <Row style={{ display: "flex", justifyContent: "center" }}>
    <iframe
      style={{ width: "1100px", height: "800px" }}
      frameBorder="0"
      allowFullScreen="true"
      src="https://app.powerbi.com/view?r=eyJrIjoiOTE5NDM2NDgtZTNlNS00YmE0LWJkNWYtMjViMDkwNTg4MTA4IiwidCI6IjQxMGYxMDE4LTI1NjUtNDdhZi04YWViLTQ1MzY2OTg0MWZlNyIsImMiOjl9"
      title="VideoToText"
    />
  </Row>
);

const CCTV = () => (
  <Row style={{ display: "flex", justifyContent: "center" }}>
    <img style={{ width: "1100px", height: "750px" }} src={cctv} alt="cctv" />
  </Row>
);

const IOT = () => (
  <Row style={{ display: "flex", justifyContent: "center" }}>
    <iframe
      style={{ width: "1050px", height: "900px" }}
      frameBorder="0"
      allowFullScreen="true"
      src="https://app.powerbi.com/view?r=eyJrIjoiYmQ1YjM1NDItOTgyYS00OWI1LWFiMDgtYjc4ZDIzYjRlOWU5IiwidCI6IjQxMGYxMDE4LTI1NjUtNDdhZi04YWViLTQ1MzY2OTg0MWZlNyIsImMiOjl9"
      title="VideoToText"
    />
  </Row>
);

const OrganicMediaAnalytics = () => (
  <Row style={{ display: "flex", justifyContent: "center" }}>
    <iframe
      title="d"
      style={{ width: "75vw", height: "100vh" }}
      frameBorder="0"
      allowFullScreen="true"
      src="https://app.powerbi.com/view?r=eyJrIjoiODk2MDg3ODYtYzkzOS00MWEwLWJmMzAtNWQ4MjU1NWVmMjEyIiwidCI6IjQxMGYxMDE4LTI1NjUtNDdhZi04YWViLTQ1MzY2OTg0MWZlNyIsImMiOjl9"
    />
  </Row>
);

const Paidmedia = () => (
  <Row style={{ display: "flex", justifyContent: "center" }}>
    <iframe
      title="d"
      style={{ width: "1100px", height: "850px" }}
      frameBorder="0"
      allowFullScreen="true"
      src="https://app.powerbi.com/view?r=eyJrIjoiMzc2YmQ0M2UtY2I0Mi00ZmI1LThhZDctNzgyOWY0ZGRlMTUwIiwidCI6IjQxMGYxMDE4LTI1NjUtNDdhZi04YWViLTQ1MzY2OTg0MWZlNyIsImMiOjl9"
    />
  </Row>
);

const Stockanalytics = () => (
  <Row style={{ display: "flex", justifyContent: "center" }}>
    <iframe
      title="d"
      style={{ width: "1100px", height: "850px" }}
      frameBorder="0"
      allowFullScreen="true"
      src="https://app.powerbi.com/view?r=eyJrIjoiODM5OTc1MDYtMzc1Ny00ZWM5LWJmYTItNmU3YjNlMzU2NjQwIiwidCI6IjQxMGYxMDE4LTI1NjUtNDdhZi04YWViLTQ1MzY2OTg0MWZlNyIsImMiOjl9"
    />
  </Row>
);

const Websiteanalytics = () => (
  <Row style={{ display: "flex", justifyContent: "center" }}>
    <iframe
      title="d"
      style={{ width: "1100px", height: "800px" }}
      frameBorder="0"
      allowFullScreen="true"
      src="https://app.powerbi.com/view?r=eyJrIjoiOTg4YmNmM2MtZWMxYS00M2FhLTk3MWYtM2NkNjZlNjM3ZGY2IiwidCI6IjQxMGYxMDE4LTI1NjUtNDdhZi04YWViLTQ1MzY2OTg0MWZlNyIsImMiOjl9

"
    />
  </Row>
);

const GA4 = () => (
  <Row style={{ display: "flex", justifyContent: "center" }}>
    <iframe
      title="d"
      style={{ width: "1100px", height: "800px" }}
      frameBorder="0"
      allowFullScreen="true"
      src="https://lookerstudio.google.com/embed/reporting/b7317c4e-a22a-4d19-a8a9-1e656b3d3c05/page/kIV1C"
    />
  </Row>
);

const LinkedIN = () => (
  <Row style={{ display: "flex", justifyContent: "center" }}>
    <iframe
      title="d"
      style={{ width: "1100px", height: "800px" }}
      frameBorder="0"
      allowFullScreen="true"
      src="https://lookerstudio.google.com/embed/reporting/fd8e11db-75c5-4fa7-83f4-41acce57ebc0/page/DRLjD"
    />
  </Row>
);

const Tax = () => (
  <Row style={{ display: "flex", justifyContent: "center" }}>
    <iframe
      title="d"
      style={{ width: "1100px", height: "800px" }}
      frameBorder="0"
      allowFullScreen="true"
      src="https://app.powerbi.com/view?r=eyJrIjoiNTEzN2I0NjktZTEzNS00MjNjLTk0NjUtOWRlMzczM2YzNmMxIiwidCI6IjQxMGYxMDE4LTI1NjUtNDdhZi04YWViLTQ1MzY2OTg0MWZlNyIsImMiOjl9"
    />
  </Row>
);
const Computervision = () => (
  <Row style={{ display: "flex", justifyContent: "center" }}>
    <div style={{}}>
      <iframe
        style={{ width: "70vw" }}
        src="https://datapilotcom-my.sharepoint.com/personal/ali_mojiz_data-pilot_com/_layouts/15/embed.aspx?UniqueId=b888e997-db7d-4c9d-ab17-98a414a3516c&embed=%7B%22af%22%3Atrue%2C%22ust%22%3Atrue%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create/?embedded=true"
        width="1280"
        height="720"
        frameborder="0"
        scrolling="no"
        allowfullscreen
        title="Garment Defect Detection AI - model vs actual.mp4"
      ></iframe>
    </div>
  </Row>
);

const Chatbot = () => (
  <Row style={{ display: "flex", justifyContent: "center" }}>
    <iframe
      src="https://datapilot-chatbot.streamlit.app/?embedded=true"
      width="940"
      height="600"
      frameBorder="0"
      scrolling="no"
      allowfullscreen
      title="Garment Defect Detection AI - model vs actual.mp4"
    ></iframe>
  </Row>
);

const Creditriskmodel = () => (
  <Row style={{ display: "flex", justifyContent: "center" }}>
    <iframe
      src="https://dp-cra.streamlit.app/?embedded=true"
      width="1000"
      height="800"
      frameBorder="0"
      scrolling="no"
      allowfullscreen
      title="Garment Defect Detection AI - model vs actual.mp4"
    ></iframe>
  </Row>
);

const Ocr = () => (
  <Row style={{ display: "flex", justifyContent: "center" }}>
    <iframe
      style={{ width: "1000px", height: "450px" }}
      frameBorder="0"
      allowFullScreen="true"
      src="https://app-ocr-5fruz6mrkkfozf3wau24ay.streamlit.app/?embedded=true"
      title="Ocr"
    />
  </Row>
);

const App = () => {
  const location = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState("d1");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const selectedKey = params.get("selectedKey");
    if (selectedKey) {
      setSelectedMenuItem(selectedKey);
    }
  }, [location.search]);

  const [collapsed, setCollapsed] = useState(false);

  const isMobile = window.innerWidth >= 1448;
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const handleLogoutClick = () => {
    setIsLoggedIn(false);
    localStorage.removeItem(config.keys.user);
    localStorage.removeItem(config.keys.token);
    axiosObj.removeHeaders();
    window.location = config.routes.login;
    // additional code to clear any user data and perform any necessary cleanup tasks
  };
  const handleMenuClick = ({ key }) => {
    setSelectedMenuItem(key);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible={false}
        style={{
          background: "#050C2C",
          // position: "fixed",
          left: 0,
          height: "100vh",
          zIndex: 1,
          padding: 0,
          margin: 0,
        }}
        width="20vw"
      >
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[selectedMenuItem]}
          onClick={handleMenuClick}
          style={{ background: "#050C2C" }}
        >
          <Menu.Item
            key="logo"
            style={{
              paddingTop: "3vw",
              paddingBottom: "2.7vw",
              background: "transparent",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={dplogosidebar}
                alt="logo"
                width={140}
                height={50}
                style={{ padding: 0, margin: 0, display: "block" }}
              />
            </div>
          </Menu.Item>
          <hr style={{ color: "grey", width: "100%" }}></hr>
          <SubMenu
            key="Insights and Analytics"
            style={{
              borderBottom: "1px solid grey",
              borderRadius: "0px",
              width: "100%",
              fontFamily: "roboto",
              fontSize: "15px",
              backgroundColor: "none",
            }}
            title={
              <span style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={ia}
                  alt="Dummy Icon"
                  style={{ marginRight: "8px", width: "16px", height: "16px" }}
                />
                Insights and Analytics
              </span>
            }
          >
            <SubMenu key="Marketing Analytics" title="Marketing Analytics">
              <Menu.Item key="d1">Organic Media Analytics</Menu.Item>
              <Menu.Item key="d2"> Paid Media Analysis</Menu.Item>
              <Menu.Item key="d3">Website Analytics</Menu.Item>
              <Menu.Item key="d23">GA 4</Menu.Item>
              <Menu.Item key="d24">LinkedIn Pages</Menu.Item>
            </SubMenu>

            <SubMenu
              key="Sales, Product and Supply Chain Analytics"
              title="Sales, Product and Supply Chain Analytics​"
            >
              <Menu.Item key="d8">Channel Analytics</Menu.Item>
              <Menu.Item key="d9">Stock Analytics</Menu.Item>
            </SubMenu>
            <SubMenu key="IoT Analytics" title="IoT Analytics​">
              <Menu.Item key="d10">CCTV - Real time analytics</Menu.Item>
              <Menu.Item key="d11">Fleet Tracking</Menu.Item>
              <Menu.Item key="d15">Energy Management System</Menu.Item>
              {/* <Menu.Item key="d22">ELM</Menu.Item> */}
            </SubMenu>
            <SubMenu
              key="Public Sector Analytics"
              title="Public Sector Analytics​"
            >
              <Menu.Item key="d13">Tax Revenue</Menu.Item>
            </SubMenu>
          </SubMenu>

          <SubMenu
            key="Artificial Intelligence and ML"
            style={{
              borderBottom: "1px solid grey",
              borderRadius: "0px",
              width: "100%",
              fontFamily: "Roboto",
              fontSize: "15px",
            }}
            title={
              <span style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={ml}
                  alt="Dummy Icon"
                  style={{ marginRight: "8px", width: "16px", height: "16px" }}
                />
                Artificial Intelligence and ML
              </span>
            }
          >
            <Menu.Item key="d16">Computer Vision</Menu.Item>
            <Menu.Item key="d17">Predictive Analytics</Menu.Item>
            <Menu.Item key="d18">Chatbot</Menu.Item>
            <Menu.Item key="d19">Credit Risk Assessment​</Menu.Item>
          </SubMenu>

          <SubMenu
            key="Robotic Process Automation"
            style={{
              borderBottom: "1px solid grey",
              borderRadius: "0px",
              width: "100%",
              fontFamily: "Roboto",
              fontSize: "15px",
            }}
            title={
              <span style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={robot}
                  alt="Dummy Icon"
                  style={{ marginRight: "8px", width: "16px", height: "16px" }}
                />
                Robotic Process Automation
              </span>
            }
          >
            <Menu.Item key="d20">Optical Character Recognition</Menu.Item>
            <Menu.Item key="d21">Video To  Text​</Menu.Item>
          </SubMenu>
        </Menu>

        <Menu
          theme="dark"
          mode="inline"
          style={{
            background: "#050C2C",
            position: "absolute",
            bottom: 0,
            width: "100%",
          }}
        >
          <Menu.Item
            style={{
              paddingTop: "5vw",
              paddingBottom: "3.5vw",
              background: "transparent",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ paddingRight: "1vw" }}>
                <img src={greendp} alt="logout"></img>
              </div>
              <div style={{ display: "block", lineHeight: "10px" }}>
                <p>Data Pilot </p>
                <p>demo@data-pilot.com</p>
                <div
                  onClick={handleLogoutClick}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    lineHeight: "0px",
                  }}
                >
                  <img
                    src={poweroff}
                    style={{ height: "20px", width: "20px" }}
                    alt="poweroff"
                  ></img>
                  <p style={{ margin: 0 }}> Sign Out</p>
                </div>
              </div>
            </div>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout style={{ transition: "margin 0.2s", height: "100vh" }}>
        <div
          style={{
            background: "#fff",
            padding: 0,
            fontSize: "20px",
            // position: "fixed",
            fontFamily: "Merriweather",
            width: "100%",
            display: "flex",
          }}
        >
          {selectedMenuItem === "d1" && (
            <h3 style={{ paddingLeft: "3vw", fontFamily: "Merriweather" }}>
              Organic Media Analytics
            </h3>
          )}
          {selectedMenuItem === "d2" && (
            <h3 style={{ paddingLeft: "3vw" }}>Paid Media Analysis</h3>
          )}
          {selectedMenuItem === "d3" && (
            <h3 style={{ paddingLeft: "3vw" }}>Website Analytics</h3>
          )}
          {selectedMenuItem === "d8" && (
            <h3 style={{ paddingLeft: "3vw" }}>Channel Analytics</h3>
          )}
          {selectedMenuItem === "d9" && (
            <h3 style={{ paddingLeft: "3vw" }}>Stock Analytics</h3>
          )}
          {selectedMenuItem === "d10" && (
            <h3 style={{ paddingLeft: "3vw" }}>CCTV - Real time analytics</h3>
          )}
          {selectedMenuItem === "d11" && (
            <h3 style={{ paddingLeft: "3vw" }}>Fleet Tracking</h3>
          )}
          {selectedMenuItem === "d13" && (
            <h3 style={{ paddingLeft: "3vw" }}>Tax Revenue Dashboard</h3>
          )}
          {selectedMenuItem === "d15" && (
            <h3 style={{ paddingLeft: "3vw" }}>Energy Management System</h3>
          )}
          {selectedMenuItem === "d17" && (
            <h3 style={{ paddingLeft: "3vw" }}>Predictive Analytics</h3>
          )}
          {selectedMenuItem === "d16" && (
            <h3 style={{ paddingLeft: "3vw" }}>
              Computer Vision - Garment Defect Detection
            </h3>
          )}
          {selectedMenuItem === "d18" && (
            <h3 style={{ paddingLeft: "3vw" }}>Chatbot</h3>
          )}
          {selectedMenuItem === "d21" && (
            <h3 style={{ paddingLeft: "3vw" }}>Video To  Text</h3>
          )}
          {selectedMenuItem === "d19" && (
            <h3 style={{ paddingLeft: "3vw" }}>Credit Risk Model</h3>
          )}
          {selectedMenuItem === "d20" && (
            <h3 style={{ paddingLeft: "3vw" }}>
              Optical Character Recognition
            </h3>
          )}
          {selectedMenuItem === "d23" && (
            <h3 style={{ paddingLeft: "3vw" }}>GA 4</h3>
          )}
          {selectedMenuItem === "d24" && (
            <h3 style={{ paddingLeft: "3vw" }}>LinkedIn Pages</h3>
          )}
          {/* {selectedMenuItem === "d22" && (
            <h3 style={{ paddingLeft: "3vw" }}>
             ELM
            </h3>
          )} */}
        </div>
        <Content
          style={{
            paddingLeft: "2vw",
            backgroundColor: "white",
            overflow: "auto",
          }}
        >
          {selectedMenuItem === "user" && (
            <SubMenu key="user" title="User">
              <Menu.Item key="user1">User 1</Menu.Item>
              <Menu.Item key="user2">User 2</Menu.Item>
            </SubMenu>
          )}

          {selectedMenuItem === "d1" && <OrganicMediaAnalytics />}
          {selectedMenuItem === "d2" && <Paidmedia />}
          {selectedMenuItem === "d3" && <Websiteanalytics />}
          {selectedMenuItem === "d8" && <ChannelAnalytics />}
          {selectedMenuItem === "d9" && <Stockanalytics />}

          {selectedMenuItem === "d10" && <ELM />}
          {selectedMenuItem === "d11" && <IOT />}
          {selectedMenuItem === "d13" && <Tax />}
          {selectedMenuItem === "d15" && <EMS />}
          {selectedMenuItem === "d21" && <Videototext />}
          {selectedMenuItem === "d19" && <Creditriskmodel />}
          {selectedMenuItem === "d20" && <Ocr />}
          {selectedMenuItem === "d17" && <Predictiveanalytics />}
          {selectedMenuItem === "d16" && <Computervision />}
          {selectedMenuItem === "d18" && <Chatbot />}
          {selectedMenuItem === "d23" && <GA4 />}
          {selectedMenuItem === "d24" && <LinkedIN />}
          {/* {selectedMenuItem === "d22" && <ELM />} */}
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
