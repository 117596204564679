// import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import AppRouter from "./AppRouter";
// import theme from "./GlobalStyles/theme";
import SessionContext from "./context/SessionContext";
import SessionValidator from "./components/core/SessionValidator";

export default function App() {
  const [isSessionExpired, setSessionExpired] = React.useState(false);
  return (
    <React.Fragment>
      {/* <ThemeProvider theme={theme}> */}
      <SessionContext.Provider value={{ isSessionExpired, setSessionExpired }}>
        <AppRouter />
        <SessionValidator isSessionExpired={isSessionExpired} />
      </SessionContext.Provider>
      {/* </ThemeProvider> */}
    </React.Fragment>
  );
}
