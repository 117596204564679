import React, { useEffect } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import PublicRoute from "./components/routes/PublicRoute";

import config from "./config";
import Dashboard from "./pages/Dashboard";
import Maindashboard from "./pages/Maindashboard";
import Users from "./pages/Users";

import Login from "./pages/Login";
import axios from "./config/axios";
import AdminRoute from "./components/routes/AdminRoute";
import RpaRoute from "./components/routes/RpaRoute";

import ComputervisionRoute from "./components/routes/ComputervisionRoute";

import CreditriskRoute from "./components/routes/CreditriskRoute";

import VttRoute from "./components/routes/VttRoute";
import MarketinganalyticsRoute from "./components/routes/MarketinganalyticsRoute";
import B2BMarketinganalyticsRoute from "./components/routes/B2BMarketinganalyticsRoute";
import DashboardRoute from "./components/routes/DashboardRoute";
import CustomersegmentationRoute from "./components/routes/CustomersegmentationRoute";
import OcrRoute from "./components/routes/OcrRoute";
import PredictiveRoute from "./components/routes/PredictiveRoute";
import { getCurrentUser } from "./services/auth";

import GuestUserRoute from "./components/routes/GuestUserRoute";
import CaresolRoute from './components/routes/CaresolRoute'
import Creditriskmodel from "./pages/Creditriskmodel";
import Rpa from "./pages/Rpa";
import Marketinganalytics from "./pages/Marketinganalytics";
import B2BMarketinganalytics from "./pages/B2BMarketinganalytics";
import Videototext from "./pages/Videototext";
import Ocr from "./pages/Ocr";
import Customersegmentation from "./pages/Customersegmentation";
import Computervision from "./pages/Computervision";
import Predictiveanalytics from "./pages/Predictiveanalytics";
import Caresol from './pages/Caresol'

export default function AppRouter() {
  const token = localStorage.getItem(config.keys.token);
  const user = localStorage.getItem(config.keys.user);

  useEffect(() => {
    window.addEventListener("load", () => {
      axios.setHeaders(token);
      setTimeout(() => {
        if (user) {
          getCurrentUser()
            .then((res) => {
              localStorage.setItem(
                config.keys.user,
                JSON.stringify(res.data.user)
              );
            })
            .catch((ex) => {
              if (ex.response && ex.response.status === 401) {
                // session.setSessionExpired(true);
              }
            });
        }
      }, 2000);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <PublicRoute
            path={config.routes.login}
            component={Login}
            // component={LoginGuestUser}
            restricted={true}
            exact
          />
          <GuestUserRoute
            path={config.routes.maindashboard}
            component={Maindashboard}
          />
          <CaresolRoute
            path={config.routes.maindashboards}
            component={Caresol}
          />
          <MarketinganalyticsRoute
            path={config.routes.marketinganalytics}
            component={Marketinganalytics}
          />
          <B2BMarketinganalyticsRoute
            path={config.routes.b2bmarketinganalytics}
            component={B2BMarketinganalytics}
          />
          <DashboardRoute
            path={config.routes.dashboard}
            component={Dashboard}
          />

          {/* <GuestUserRoute
            path={config.routes.dashboard}
            component={Dashboard}
          /> */}
          <AdminRoute path={config.routes.users} component={Users} />
          <CustomersegmentationRoute
            path={config.routes.customersegmentation}
            component={Customersegmentation}
          />
          <RpaRoute path={config.routes.rpa} component={Rpa} />
          <VttRoute path={config.routes.videototext} component={Videototext} />
          <OcrRoute path={config.routes.ocr} component={Ocr} />
          <ComputervisionRoute
            path={config.routes.computervision}
            component={Computervision}
          />

<CreditriskRoute
            path={config.routes.creditriskmodel}
            component={Creditriskmodel}
          />

          <PredictiveRoute
            path={config.routes.predictiveanalytics}
            component={Predictiveanalytics}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}
