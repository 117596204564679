import React, { useState, useEffect } from "react";
// import InputAdornment from "@mui/material/InputAdornment";
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
// import { IconButton } from "rsuite";
// import { Search } from "@material-ui/icons";
//import {ButtonStyle }from "@material-ui/icons";


import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
// import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import * as yup from "yup";
import { getCurrentUser, signin } from "../services/auth";
import config from "../config";
import axios from "../config/axios";
import { Box, CircularProgress, Paper} from "@material-ui/core";

import bg6 from "../res/images/bg6.png";
import bg7 from "../res/images/bg7.jpeg";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import useGlobalStyles from "../globalStyles/globalStyles";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  pageBackground: {
    width: "100%",
    height: "100vh",
    // width: "100%",
    // height: "100vh",
    overflow: "hidden",
    backgroundImage: `url(${bg7})`,
    // backgroundImage: `url(${bg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundBlendMode: "multiply",
  },
  avatar: {
    margin: theme.spacing(1),
    // width:"15mm",
    // height:"5.5mm",
    width: theme.spacing(18),
    height: theme.spacing(8),
    // height: theme.spacing(10),
    backgroundColor: "transparent",
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    textAlign: "center",
    color: "red",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

let schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export default function SignIn(props) {
  const classes = useStyles();
  const globalStyles = useGlobalStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setError("");
  }, [email, password]);
  const userSignin = async (e) => {
    e.preventDefault();
    schema
      .validate({ email, password })
      .then(async () => {
        try {
          setError("");
          setIsLoading(true);
          const res = await signin({ email, password });
          const token = res.data.token;
          localStorage.setItem(config.keys.token, token);
          axios.setHeaders(token);
          const newRes = await getCurrentUser();
          console.log(newRes);
          localStorage.setItem(
            config.keys.user,
            JSON.stringify(newRes.data.user)
          );
          //   window.location = config.routes.dashboard;
          setTimeout(() => {
            console.log("in timeout");
            console.log(localStorage.getItem("user"));

            props.history.push(config.routes.maindashboard);
          }, 4000);
          //   history.push(config.routes.dashboard);
        } catch (ex) {
          console.log(ex);

          setIsLoading(false);
          console.log(JSON.stringify(ex));
          setError(ex.response.data.message);
        }
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
        setError(err.message);
      });
  };
  return (
    <div className={classes.pageBackground}>
      <Box
        height="100%"
        // display="flex"
        // alignItems="center"
        //justifyContent="center"
      >
        <Box
          // style={{ marginTop: "25vh" }}
          style={{ marginTop: "15vh" }}
        >
          <Container component="main" maxWidth="xs">
            <Paper
              className={globalStyles.paper}
              //style={{border: '1px solid rgba(0, 0, 0, 0.05)',}}
            >
              <CssBaseline />
              {/* <Paper className={globalClasses.paper}> */}

              <div className={classes.paper} style={{paddingTop:"2vw"}}>
                {/* {!isLoading ? (
                  <img className={classes.avatar} src={bg6} alt="avatar" />
                ) : (
                  <CircularProgress />
                )} */}
               

                <form className={classes.form} noValidate onSubmit={userSignin}>
                  {error && <Alert severity="error">{error}</Alert>}

                

                  <TextField
                    InputLabelProps={{ style: { color: "white" } }}
                    borderColor="green !important"
                    InputProps={{ style: { color: "white" } }}
                    style={{
                      borderColor: "white",
                      borderRadius: 10,
                      fontFamily: "Roboto",
                      padding: "5px 5px",
                      fontSize: "12px",
                      color: "white",
                      //border:"1px solid gray"
                    }}
                    // variant="outlined"
                    margin="normal"
                    required
                    size="small"
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    // autoFocus
                    onChange={(e) => {
                      setError("");
                      setEmail(e.currentTarget.value);
                    }}
                  />

                  <TextField
                    InputLabelProps={{ style: { color: "white" } }}
                    InputProps={{ style: { color: "white" } }}
                    style={{
                      borderRadius: 10,
                      fontFamily: "Roboto",
                      padding: "5px 5px",
                      fontSize: "12px",
                      color: "white",

                      //border:"1px solid gray"
                    }}
                    // variant="outlined"
                    margin="normal"
                    required
                    size="small"
                    fullWidth
                    id="email"
                    label="Password"
                    type="password"
                    name="Password"
                    autoComplete="Password"
                    autoFocus
                    onChange={(e) => {
                      setError("");
                      setPassword(e.currentTarget.value);
                    }}
                  />

                  <FormGroup style={{ fontFamily: "Roboto", color: "white" }}>
                    <FormControlLabel
                      control={<Checkbox color="default" />}
                      label="Remember me"
                    />
                    {/* <TextField id="standard-basic" label="Standard" variant="standard" /> */}
                  </FormGroup>
                  <Button
                    style={{
                      borderRadius: 10,
                      backgroundColor: "#059baa",
                      padding: "10px 10px",
                      //color: "#F9fdfd",
                      color: "white",
                      fontSize: "12px",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontFamily: "Roboto",
                      width: "200px",
                      height: "40px",
                    }}
                    type="submit"
                    //halfWidth
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    disabled={Boolean(isLoading)}
                  >
                    LogIn
                  </Button>
                  {/* 
               <Button 
              style={{ borderWidth:1,
               borderColor:'rgba(0,0,0,0.2)',
               alignItems:'center',
               justifyContent:'center',
               width:30,
               height:25,
               backgroundColor:'#fff',
               borderRadius:100,
             }}
                 type="submit"
                 fullWidth
                 variant="contained"
                 color="secondary"
                 className={classes.submit}
                 disabled={Boolean(isLoading)}
               >
                 Sign In
               </Button> */}

                  {/* <p style={{
                    textAlign: "center",
                    color: "#059baa",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                    paddingBottom: "5px",
                    fontSize: "10px"
                  }}>
                    New here? Create an account
                  </p>
 */}
                </form>
              </div>

              {/* <Box mt={8}>
       <Footer />
       </Box> */}
            </Paper>
          </Container>
        </Box>
      </Box>
    </div>
  );
}
