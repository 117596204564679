import React, { useEffect, useRef } from "react";
import "./Sidercomponent.css";
import config from "../config";
import { Col, Row } from "antd";
import { useHistory } from "react-router";
import arrowrightinsight from "../res/images/arrowright.png";
import purplearrow from '../res/images/purplearrow.png'
import yellowarrow from '../res/images/yellowarrow.png'
import insight from "../res/images/insight.svg";
import ai from "../res/images/ai.svg";
import rp from "../res/images/rp.svg";



const SliderComponent = () => {
  const slideIndexRef = useRef(1);
  const history = useHistory();

 

 

  return (
    <>
      <Row
        align="middle"
        style={{ height: "100vh", margin: 0 , backgroundColor:"white", overflow:"hidden"}}
        justify="space-around"
       
      >
        <Col
          span={7}
          className="centered-col"
         
          style={{ backgroundColor: "#E9FDFF" }}
        >
          <div
            className="left-aligned-content"
            style={{ display: "block", marginTop: "5vw" }}
          >
            <div>
              <img src={insight} alt="insightmain" />
            </div>
            <div>
              <p
                style={{
                  fontFamily: "merriweather",
                  fontWeight: "bold",
                  fontSize: "25px",
                }}
              >
                Insight <br/>&Analytics
              </p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}   >
              <p className="sub-content"
              
              onClick={() => history.push(`${config.routes.maindashboards}?selectedKey=d1`)}
              >Marketing Analytics</p>
              <img
                src={arrowrightinsight}
                className="img-content"
                alt="arrowrightinsight"
                onClick={() => history.push(`${config.routes.maindashboards}?selectedKey=d1`)}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="sub-content"
               onClick={() => history.push(`${config.routes.maindashboards}?selectedKey=d8`)}
              >
                Sales, Product & Supply Chain
              </p>
              <img
                src={arrowrightinsight}
                className="img-content"
                alt="arrowrightinsight"
                onClick={() => history.push(`${config.routes.maindashboards}?selectedKey=d8`)}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="sub-content"
               onClick={() => history.push(`${config.routes.maindashboards}?selectedKey=d10`)}>IoT Analytics</p>
              <img
                src={arrowrightinsight}
                className="img-content"
                alt="arrowrightinsight"
                onClick={() => history.push(`${config.routes.maindashboards}?selectedKey=d10`)}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="sub-content"
               onClick={() => history.push(`${config.routes.maindashboards}?selectedKey=d13`)}
              >Public Sector Analytics</p>
              <img
                src={arrowrightinsight}
                className="img-content"
                alt="arrowrightinsight"
                onClick={() => history.push(`${config.routes.maindashboards}?selectedKey=d13`)}
              />
            </div>
          </div>
        </Col>
        <Col
             span={7}
          style={{ backgroundColor: "#F4F4FF" }}
          className="centered-col gutter-row "
        >
          <div
            className="left-aligned-content"
            style={{ display: "block", marginTop: "7vw" }}
          >
            <img src={ai} alt="insightmain" />

            <p
              style={{
                fontFamily: "merriweather",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              Artificial Intelligence<br />&Machine Learning
            </p>

            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="sub-content"
               onClick={() => history.push(`${config.routes.maindashboards}?selectedKey=d16`)}
              >Computer Vision</p>
              <img
                src={purplearrow}
                className="img-content"
                alt="arrowrightinsight"
                onClick={() => history.push(`${config.routes.maindashboards}?selectedKey=d16`)}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="sub-content"
               onClick={() => history.push(`${config.routes.maindashboards}?selectedKey=d17`)}
              >
              Predictive Analytics
              </p>
              <img
                 src={purplearrow}
                className="img-content"
                alt="arrowrightinsight"
                onClick={() => history.push(`${config.routes.maindashboards}?selectedKey=d17`)}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="sub-content"
               onClick={() => history.push(`${config.routes.maindashboards}?selectedKey=d18`)}
              >Chatbot</p>
              <img
                   src={purplearrow}
                className="img-content"
                alt="arrowrightinsight"
                onClick={() => history.push(`${config.routes.maindashboards}?selectedKey=d18`)}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="sub-content"
               onClick={() => history.push(`${config.routes.maindashboards}?selectedKey=d19`)}
              >Credit Risk Assessment</p>
              <img
                    src={purplearrow}
                className="img-content"
                alt="arrowrightinsight"
                onClick={() => history.push(`${config.routes.maindashboards}?selectedKey=d19`)}
              />
            </div>
          </div>
        </Col>
        <Col
             span={7}
          style={{ backgroundColor: "#FFF7EE" , }}
          className="centered-col gutter-row"
        >
          <div
            className="left-aligned-content"
            style={{ display: "block", marginTop: "9vw" }}
          >
    
              <img src={rp} alt="insightmain" />
        
       
              <p  style={{
                fontFamily: "merriweather",
                fontWeight: "bold",
                fontSize: "25px",
              }}>Robotic Process <br/>Automation</p>
    
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="sub-content"
               onClick={() => history.push(`${config.routes.maindashboards}?selectedKey=d20`)}
               >Optical Character Recognition</p>
              <img
                src={yellowarrow}
                className="img-content"
                alt="arrowrightinsight"
                onClick={() => history.push(`${config.routes.maindashboards}?selectedKey=d20`)}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="sub-content"
               onClick={() => history.push(`${config.routes.maindashboards}?selectedKey=d21`)}
              >
              Video to Text              </p>
              <img
                 src={yellowarrow}
                className="img-content"
                alt="arrowrightinsight"
                onClick={() => history.push(`${config.routes.maindashboards}?selectedKey=d21`)}
              />
            </div>
            
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SliderComponent;
